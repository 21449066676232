(function ($) {

    // Create job object
    var __ = {

        dom: {
            container: $('.j-trail-map'),
            map: $('.j-trail-map--map'),
            types: $('.j-trail-map--types'),
            locations: $('.j-trail-map--locations'),
        },
        locations: "",
        home: "",
        currentType: $('.j-trail-map--map').data('starting-category'),
        color: "",
        zindex: 5,
        selectedCategories: $('.j-trail-map--map').data('categories'),
        kmlFile: $('.j-trail-map--map').data('kml'),
        map: {
            object: '',
            bounds: '',
            options: {
                zoom: 10,
                minZoom: 1,
                maxZoom: 20,
                mapTypeId: '',
                scrollwheel: false,
                center: '',
                streetViewControl: false,
            },
            infowindow: '',
            pins: [],
            markers: [],
        },

        setupObject : function(){

            __.locations = TRAIL_MAP.locations;
            __.home = TRAIL_MAP.home;
            __.map.pins = TRAIL_MAP.pins;
            __.map.bounds = new google.maps.LatLngBounds();
            __.map.options.center = new google.maps.LatLng(
                parseFloat(TRAIL_MAP.home.lat),
                parseFloat(TRAIL_MAP.home.lng)
            );
            __.map.options.mapTypeId = google.maps.MapTypeId.ROADMAP;
            __.map.infowindow = new google.maps.InfoWindow({
                content: '',
            });

            console.log(TRAIL_MAP.home.lat);

        },

        // function run to setup
        setup: function () {
            // Exit if no trail map present
            if (__.dom.container.length < 0) {
                return;
            }

            __.setupObject();

            // Setup the Trail Map
            __.createMap();
            __.setupCategories();

            __.resetMapBounds();
            __.filterMarkers();
            __.setMapBounds();

            __.handleMarkerCategoryClick();
            __.handleMarkerClick();

            __.handleCloseCategory();
        },

        handleCloseCategory: function($target) {
            __.dom.container.on('click', '.trail-map--close_category', function () {
                    $('.trail-map--category_item').removeClass('active');
                    __.map.infowindow.close();
            });

        },
        handleMarkerCategoryClick: function () {
            __.dom.container.on('click', '.j-trail-map--map--type', function () {
                var _this = $(this);


                __.map.infowindow.close();

                _this.parent().addClass('active').siblings().removeClass('active');
                $('.v-trail-map--location').removeClass('active');

                __.currentType = _this.data('type');
                //$('.v-trail-map--location-set__' + __.currentType).addClass('active').siblings().removeClass('active');

                //__.filterMarkers();
            });
        },
        handleMarkerClick: function () {
            __.dom.container.on('click', '.j-trail-map--map--location', function () {
                var _this = $(this);
                var markerID = _this.data('marker-id');

                __.map.infowindow.close();

                $('.v-trail-map--location').removeClass('active');
                _this.addClass('active');

                __.openInfoWindow( __.map.markers[markerID].pin, __.locations[markerID].infowindow );


            });
        },

        // Setup the Trail Map
        createMap: function () {
            
            __.map.object = new google.maps.Map(__.dom.map.get(0), __.map.options);

            var src = __.kmlFile;
            
            if (src) {
                var kmlLayer = new google.maps.KmlLayer(src, {
                  suppressInfoWindows: true,
                  preserveViewport: false,
                  map: __.map.object
                });
            }
        },

        setupCategories: function () {
            for (var i in __.locations) {
                var location = __.locations[i];
                var marker = {
                    'ID': i,
                    'type': location.type,
                    'title': location.title,
                    'infowindow': location.infowindow,
                    'pin': location.pin,
                    'color' : location.color,
                    'latlng': {
                        lat: parseFloat(location.location.lat),
                        lng: parseFloat(location.location.lng)
                    }
                };

                __.setMarker(marker);
            }
        },

        setMarker: function (marker) {
            __.map.markers[marker.ID] = {
                pin: new google.maps.Marker({
                    location_id: marker.type,
                    position: marker.latlng,
                    map: __.map.object,
                    content: '<div class="info-window-text"><p>' + marker.title + '</p></div>',
                    zIndex: __.zindex++,
                    icon: {
                        // path: "M107.489583,210.894531 C100.374472,210.894531 94.65625,216.612753 94.65625,223.727865 C94.65625,235.977865 107.489583,245.894531 107.489583,245.894531 C107.489583,245.894531 120.322917,235.977865 120.322917,223.727865 C120.322917,216.612753 114.604694,210.894531 107.489583,210.894531 Z",
                        path: "M107,210 C100,210 94,216 94,223 C94,235 107,245 107,245 C107,245 120,235 120,223 C120,216 114,210 107,210 Z",
                        scale: 1,
                        strokeWeight: 0.2,
                        strokeColor: marker.color,
                        strokeOpacity: 1,
                        fillColor: marker.color,
                        fillOpacity: 1,
                        anchor: new google.maps.Point(107, 245)
                    },
                }),
                icon: new google.maps.Marker({
                    location_id: marker.type,
                    position: marker.latlng,
                    map: __.map.object,
                    content: '',
                    zIndex: __.zindex++,
                    icon: {
                        url: marker.pin.url,
                        // This marker is 20 pixels wide by 32 pixels high.
                        size: new google.maps.Size(marker.pin.width, marker.pin.height),
                        // The origin for this image is (0, 0).
                        origin: new google.maps.Point(0, 0),
                        // The anchor for this image is the base of the flagpole at (0, 32).
                        anchor: new google.maps.Point(marker.pin.width / 2, 30)
                    },
                })
            };

            __.map.markers[marker.ID].pin.addListener('click', function() {
                $('.v-trail-map--location').removeClass('active');
                __.openInfoWindow(__.map.markers[marker.ID].pin, marker.infowindow, marker.ID);
            });
            __.map.markers[marker.ID].icon.addListener('click', function() {
                $('.v-trail-map--location').removeClass('active');
                __.openInfoWindow(__.map.markers[marker.ID].pin, marker.infowindow, marker.ID);
            });
        },
        openInfoWindow: function(marker, content, id){
            __.map.infowindow.setContent(content);
            __.map.infowindow.open(__.map.object, marker);

            $('.v-trail-map--location[data-marker-id=' + id + ']').addClass('active');


            google.maps.event.addListener( __.map.infowindow,'closeclick',function(){
               $('.v-trail-map--location').removeClass('active');
            });
        },
        filterMarkers: function () {
            __.resetMapBounds();
            for (var key in __.locations ) {
                var location = __.locations[key];
                if (__.selectedCategories.includes( location.type ) ) {
                
                    __.map.markers[key].pin.setVisible(true);
                    __.map.markers[key].icon.setVisible(true);
                    __.bounds.extend(__.map.markers[key].pin.position);
                } else {
                    __.map.markers[key].pin.setVisible(false);
                    __.map.markers[key].icon.setVisible(false);
                }
            }
            __.setMapBounds();
        },
        resetMapBounds: function () {
            __.bounds = new google.maps.LatLngBounds();
        },
        setMapBounds: function () {
            __.map.object.fitBounds(__.bounds);
            google.maps.event.trigger(__.map.object, "resize");
        },
    };
    // Attach Toolbox to window //
    window.map = __;
})(jQuery); // Fully reference jQuery after this point.