(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
    window.sliders = {

    	/**
    	 * Global load more button functionality
    	 */
        initSlider: function ($el, $args){
        	$el.slick($args);
        },

        genericSlider: function() {
            $el = $('.js--generic-slider');

            $el.each(function() {

                $arrows = $el.data('slick-arrows');
                $dots = $el.data('slick-dots');
                $autoplay = $el.data('slick-autoplay');
                $autoplay_speed = $el.data('slick-autoplayspeed');

                $args = {       
                    arrows:$arrows,
                    dots: $dots,
                    infinite: false,
                    autoplay: $autoplay,
                    autoplaySpeed: $autoplay_speed,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    customPaging : function(slider, i) {
                        return '<div class="slick-button"></div>';
                    },
                };
                window.sliders.initSlider( $el, $args );
            });
        },
        
        footerNewsSlider: function() {
            $el = $('.footer--news-slider');

            $el.each(function() {

                $arrows = $el.data('slick-arrows');
                $dots = $el.data('slick-dots');
                $autoplay = $el.data('slick-autoplay');
                $autoplay_speed = $el.data('slick-autoplayspeed');

                $args = {       
                    arrows:$arrows,
                    dots: $dots,
                    infinite: false,
                    autoplay: $autoplay,
                    autoplaySpeed: $autoplay_speed,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    responsive: [
                        {
                          breakpoint: 1192,
                          settings: {
                            slidesToShow: 1,
                          }
                        }
                    ],
                    customPaging : function(slider, i) {
                        return '<div class="slick-button"></div>';
                    },
                };
                window.sliders.initSlider( $el, $args );
            });
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper

/*

slidesToShow: 4,
slidesToScroll: 4,
responsive: [
{
  breakpoint: 1024,
  settings: {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    dots: true
  }
},
{
  breakpoint: 600,
  settings: {
    slidesToShow: 2,
    slidesToScroll: 2
  }
},
{
  breakpoint: 480,
  settings: {
    slidesToShow: 1,
    slidesToScroll: 1
  }
}
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object
]


 */