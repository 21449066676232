(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
        
    var canBeLoaded = true;

    window.ajax = {

    	/**
    	 * Product Filtering Events
    	 */
    	filterEvents: function() {
    		window.ajax.desktopFilter();
    		window.ajax.mobileFilter();
		},

		/**
		 * Desktop Filter
		 */
    	desktopFilter: function() {
    		$('#terms-filter .desktop a').on('click', function(e) {

    			// disable default functionality
    			e.preventDefault();

    			// get info from event element
    			$url 		= $(this).attr('href');
    			$category 	= $(this).data('filter');
    			$title 		= $(this).text();
                $action     = $('#terms-filter').data('action');

    			// check if the event should be disabled to prevent multiple loads
    			if ( !$(this).hasClass('disabled') ) {

    				// add the disabled class
    				$(this).addClass('disabled');

	    			// load in the new products
	    			window.ajax.filterTerms($url, $category, $action);

	    			// update the selector state
	    			window.ajax.updateEventSelectorStates( $title, $url, $(this), $category, 'desktop' );
	    		}

    		});
    	},

    	/**
		 * Mobile Filter
		 */
    	mobileFilter: function() {

    		$('#select-filter').on('change', function(e) {

    			// disable default functionality
    			e.preventDefault();

    			// get selected option
				$option  = $(this).find(":selected");

				// get info selected option
				$url 	  = $option.val();
				$title 	  = $option.data('title');
				$category = $option.attr('data-filter')
                $action     = $('#terms-filter').data('action');

				// load in the new products
				window.ajax.filterTerms( $url, $category, $action );

				// update the selector state
    			window.ajax.updateEventSelectorStates( $title, $url, $(this), $category, 'mobile' );

    		});

    	},

    	/**
    	 * Scroll to the filter on event
    	 */
    	scrollToFilter: function() {
    		$target = $('#terms-filter');
    		$offset = 100;
    		$("html, body").animate(
                {
                    scrollTop: $target.offset().top - $offset
                },
                500
            );
    	},

    	/**
    	 * Update the selector states after event
    	 */
    	updateEventSelectorStates: function($title, $url, $event_element, $category, $type) {

    		// update desktop filter state
    		$('#terms-filter .desktop li').removeClass('active');
    		$('#terms-filter .desktop li a[data-filter="' + $category + '"]').parent().addClass('active');

    		// update mobile filter state, but only if on desktop. No need to update it on mobile.
    		if ($type == 'desktop') {
    			$('#terms-filter .mobile .select-filter option[value="' + $url + '"]').prop('selected', true);
    		}
    		
    		// replace history state 
    		window.history.replaceState('Object', $title, $url);

            // update banner page title
            $('.basic-banner--title_inner span').text($title);

    	},

    	/**
    	 * Load in the products via ajax
    	 */
        filterTerms: function ($url, $category, $action) {
			
        	var $post_container = $('#post-list--container');

            // add loading class to product list
            $('#loading-wrap').addClass('loading');

            $post_container.fadeOut(function(){
                $post_container.empty();

                // ajax data
                data = {
                  'url' : $url,
                  'category' : $category
                };

                // call the ajax load function
                window.ajax.postsAjaxCall($url, data);

            });

        },

        /**
         * Load in posts AJAX Call
         */
        postsAjaxCall: function($url, data) {

            if (canBeLoaded == true) {
                $.ajax({
                    url : $url, // AJAX handler
                    data : data,
                    type : 'POST',
                    beforeSend : function ( xhr ) {
                        // disable loading
                        canBeLoaded = false;
                        // scroll to top of product filter
                        window.ajax.scrollToFilter();                                              
                    },
                    success : function( data ){

                        if( data ) { 

                            // find element in data
                            $posts = $(data).find('#post-list--container > .container');

                            // cache product container
                            $posts_container = $('#post-list--container');

                            // append new data to product container
                            $posts.appendTo($posts_container);
                            $posts_container.fadeIn(1000);

                            // fade in new data and add loading class
                            $('#post-list--container .row:not(.load-more-button-container').fadeIn();
                            $('#loading-wrap').removeClass('loading');

                            // re-enable filters
                            $('#terms-filter .desktop a').removeClass('disabled');

                            // reenable loading
                            canBeLoaded = true;

                        }

                    }

                });
            }
        },

        /**
         * Global load more button functionality
         */
        loadPosts: function (){

            $(document).on('click', '.load-more-button', function(e){

                var button = $(this),
                    cat = button.data('cat'),
                    ppp = button.data('ppp'),
                    max = button.data('max'),
                    text = button.data('text'),
                    page = ajaxVars.current_page,
                    data = {
                      'page' : page,
                      'action': 'more_post_ajax',
                      'cat' : cat,
                      'ppp' : ppp
                    };

                if (canBeLoaded == true && button.length ) {

                    $.ajax({
                      url : ajaxVars.ajaxurl, // AJAX handler
                      data : data,
                      type : 'POST',
                      beforeSend : function ( xhr ) {
                        button.text('Loading...'); // change the button text
                        canBeLoaded = false; 
                      },
                      success : function( data ){

                        if( data ) {
                          $loadContainer = $('.post-list--ajax');
                          $(data).hide().appendTo($loadContainer).fadeIn(1000);
                          button.text( text );
                          page++;
                          canBeLoaded = true; // the ajax is completed, now we can run it again


                          if ( page >= max )  {
                            button.remove(); // if last page, remove the button
                            $('.load-more-button-container').remove();
                          }
                 
                          
                        } else {
                          button.remove(); // if no data, remove the button as well
                          $('.load-more-button-container').remove();
                        }
                      }
                    });
                }
            });
        }

		
    };

}(window.jQuery, window, document)); // End of jQuery wrapper