(function($, window, document) {
    // jQuery wrapper

    // Page Functions
    window.PageFunctions = {
        /**
         *  Init AOS.js
         *  @link https://michalsnik.github.io/aos/
         */
        initAOS: function() {
            AOS.init();
        },

        /**
         * Loads the social media "share" links into a new, small window
         */
        shareFunctionality: function() {
            $(".share-post a.social-link").click(function(e) {
                e.preventDefault();
                window.open(
                    $(this).attr("href"),
                    "fbShareWindow",
                    "height=450, width=550, top=" +
                        ($(window).height() / 2 - 275) +
                        ", left=" +
                        ($(window).width() / 2 - 225) +
                        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
                );
                return false;
            });
        },

        /**
         * Mobile Menu functionality
         */
        mobileMenu: function() {
            $(".menu-trigger-container").on("click", function(e) {
                if ($("body").hasClass("menu-open")) {
                    $("body").removeClass("menu-open");
                } else {
                    $("body").addClass("menu-open");
                }
            });

             // Append the "overview" item for menu items that have children
            $('.menus.mobile .menu-item-has-children:not(.has-mega-menu) > a, .menus.mobile .menu-item-has-children:not(.has-mega-menu) > span').each(function() {
                href = $(this).attr('href');
                if (href) {
                    $(this).next().prepend('<li class="menu-item menu-overview"><a href="'+href+'">Overview</a></li>');
                }
            });
        },

        /**
         * Scroll to anchor links
         */
        scrollToAnchorLinks: function() {
            $(".wrap a[href^='#']:not(.noscroll)").on("click", function(e) {
                e.preventDefault();
                href = $(this).attr("href");
                target = $(href);
                $offset = target.data('offset');

                if (!$offset) {
                    $offset = 165;
                }

                if (href.length > 1) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - $offset
                        },
                        500
                    );
                }
            });
        },

        /**
         * Updates PHP search parameters
         */
        updateSearchParameters: function(uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf("?") !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, "$1" + key + "=" + value + "$2");
            } else {
                return uri + separator + key + "=" + value;
            }
        },


        /**
         * Mobile menu accordion
         */
        mobileMenuAccordion: function() {
            // expand selected accordion
            $(".menus.mobile .menu-item-has-children > a, .menus.mobile .menu-item-has-children > span, .menus.mobile .has-mega-menu > a, .menus.mobile .has-mega-menu > span").on("click", function(e) {
                e.preventDefault();
                $(this)
                    .next()
                    .slideToggle();
                $(this).parent().toggleClass("sub-menu-open");
            });
        },

        /**
         * Accordion
         */
        accordion: function() {
            // expand selected accordion
            $(".js-accordion--title").on("click", function(e) {
                e.preventDefault();
                $(this)
                    .next()
                    .slideToggle();
                $(this).toggleClass("active");
            });
        },

        /**
         *  Add shrink class to header on scroll
         */
        shrinkHeaderOnScroll: function() {
            var $threshold = 33,
                $window = $(window);

            $window.scroll(function() {
                $scroll_position = $window.scrollTop();
                if ($scroll_position > $threshold) {
                    $("body").addClass("shrink");
                } else {
                    $("body").removeClass("shrink");
                }
            });

            // if the scroll position is greater than 0, add the shrink class
            var height = $(window).scrollTop();
            if (height > 0) {
                $("body").addClass("shrink");
            }
        },

        /**
         * Hide header on scroll
         */
        hideHeaderOnScroll: function() {
            var myElement = document.querySelector("body#top");
            // construct an instance of Headroom, passing the element
            var headroom  = new Headroom(myElement);
            // initialise
            headroom.init();
        },

        /**
         * Custom lity trigger for uniquely styled video embed code popup
         */
        videoPopupLity: function() {
            var options = {
                template:
                    '<div class="lity lity-ingredients" role="dialog" aria-label="Dialog Window (Press escape to close)" tabindex="-1"><div class="lity-wrap" data-lity-close role="document"><div class="lity-loader" aria-hidden="true">Loading...</div><div class="lity-container"><div class="lity-content"></div><button class="lity-close" type="button" aria-label="Close (Press escape to close)" data-lity-close>&times;</button></div></div></div>'
            };

            $(document).on("click", "[data-ingredients-lity]", function(e) {
                lity($(this).attr("href"), options, $(this));
            });
        },

        /**
         * Wrap all WYSIWYG tables in responsive element
         */
        wrapWYSIWYGTables: function() {
            $(".basic-content--content table").wrap(
                "<div class='responsive-table'></div>"
            );
        },

        /**
         * Init Fit Vids
         */
        initFitVids: function() {
            $(".basic-content--content").fitVids({ customSelector: "iframe[src^='https://www.facebook.com']"});
        },

        /**
         * Disable right magic mouse swipe
         */
        disableRightMagicMouse: function() {

            document.addEventListener('DOMMouseScroll', function(e) { 
                if (e.axis === e.HORIZONTAL_AXIS) {
                    e.stopPropagation(); 
                    e.preventDefault();
                    e.cancelBubble = false; 
                }
                return false;
            }, false);
        },

        /**
         * Parallax function
         * @link https://lautiamkok.github.io/js-parallax/advanced/index.html
         */
        parallax: function() {
            function isInViewport(node) {
              var rect = node.getBoundingClientRect()
              return (
                (rect.height > 0 || rect.width > 0) &&
                rect.bottom >= 0 &&
                rect.right >= 0 &&
                rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.left <= (window.innerWidth || document.documentElement.clientWidth)
              )
            }

            $(window).scroll(function() {
              var scrolled = $(window).scrollTop()
              $('.parallax').each(function(index, element) {
                var initY = $(this).offset().top
                var height = $(this).height()
                var endY  = initY + $(this).height()

                // Check if the element is in the viewport.
                var visible = isInViewport(this)
                if(visible) {
                  var diff = scrolled - initY
                  var ratio = Math.round((diff / height) * 100)
                  $(this).css('background-position','center ' + parseInt(-(ratio * 1.5)) + 'px')
                }
              })
            })
        }
    };
})(window.jQuery, window, document); // End of jQuery wrapper
